import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState, selectors } from '../store';
import { IonPopover, IonIcon, IonSelect, IonSelectOption, IonHeader, IonToolbar, IonButtons, IonButton, IonMenuButton, IonContent, IonList, IonItem, IonLabel, IonDatetime, IonTitle } from '@ionic/react';
import './About.css';
import AboutPopover from '../components/AboutPopover';
import { calendar, pin } from 'ionicons/icons';

type Props = ReturnType<typeof mapStateToProps>

type State = {
  showPopover: boolean,
  showPopoverEvent: null | MouseEvent
}

class About extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showPopover: false,
      showPopoverEvent: null
    };
  }

  presentPopover = (e: MouseEvent) => {
    this.setState(() => ({
      showPopover: true,
      showPopoverEvent: e
    }));
  }

  dismissPopover = () => {
    this.setState(() => ({
      'showPopover': false,
      'showPopoverEvent': null
    }));
  }

  render() {
    return (
      <>
        <IonHeader>
          <IonToolbar color="dark">
            <IonButtons slot="start">
              <IonMenuButton></IonMenuButton>
            </IonButtons>
            <IonTitle>About</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonPopover
          isOpen={this.state.showPopover}
          event={this.state.showPopoverEvent}
          onDidDismiss={this.dismissPopover}
        >
          <AboutPopover
            dismissPopover={this.dismissPopover}
          />
        </IonPopover>

        <IonContent color="dark">
          <div className="about-header">
            <img src="assets/img/fppd.png" alt="fppd logo" />
          </div>
          <div className="ion-padding about-info">
            <h1>Federick Pagan Pride Day</h1>

            <IonList lines="none" >
              <IonItem color="dark">
                <IonIcon icon={calendar} slot="start"></IonIcon>
                <IonContent color="dark">September 14th, 2019 <br/> 10 am &mdash; 6 pm</IonContent>
              </IonItem>

              <IonItem color="dark">
                <IonIcon icon={pin} slot="start"></IonIcon>
                <IonContent color="dark"><a href="https://www.google.com/maps/place/Unitarian+Universalist+Congregation+of+Frederick/@39.366792,-77.499078,17z/data=!3m1!4b1!4m2!3m1!1s0x89c9deeca8ef806f:0xe6f9518131fc91ef">4880 Elmer Derr Road<br />
                Frederick, MD 21703</a></IonContent>
              </IonItem>
            </IonList>

            <p>We are so excited for the 5th Annual Frederick Pagan Pride Day on Saturday, September 14th, 2019!!</p>

            <p><strong>Admission</strong> is a donation to one of two organizations we are supporting this year.  You are welcome to bring an item of non-perishable commercially prepared food (no home canned goods, unfortunately) for <a href="https://www.cityoffrederick.com/index.aspx?NID=462">The Foodbank Program</a> operated by the Frederick Community Action Agency.  This year, Frederick Pagan Pride Day is also supporting <a href="https://frederickcountymd.gov/15/Animal-Control">Frederick County Animal Control</a>.  Donations for the <a href="https://frederickcountymd.gov/114/Adopt">Adoption Center</a> can also be given as admission.  FCAC is requesting UNOPENED PACKAGES of canned food of any variety, dry food (Iams is preferred, but they will take any sort), bleach, sponges and Dawn dish soap.  Other pet supplies are welcome, but must be UNOPENED.</p>

            <p><strong>Parking</strong> will be available on-site as well as at two overflow lots.  <strong>Shuttle Service</strong> will be running from the Park and Ride lots at the intersection of Elmer Derr Road.  The shuttle will run from 9:30 am to 6 pm.  <strong>Please consider carpooling as well.</strong></p>

            <h1>Pagan Pride Day Information</h1>
            <p><strong>Who is invited to Pagan Pride Day?</strong> Pagan Pride Day is open to the general public, whether Pagan or non-Pagan. The purpose of our event is to encourage tolerance through education and information.</p>
            <p><strong>Are the rituals open to everyone?</strong> Yes, our rituals are open to all those attending the event, including children as long as they can behave in a respectful and mature manner.</p>
            <p><strong>What charities are you helping?</strong> We are collecting donations in place of admission for The Foodbank Program operated by the Frederick Community Action Agency and the Adoption Center at Frederick County Animal Control. We can also accept cash donations for Frederick CUUPS (the group that organizes and staffs Frederick Pagan Pride Day).</p>
            <p><strong>Respect &amp; Responsibility</strong> You are responsible for your welfare and the welfare of any children in your care, as well as any personal property in your care. Please remember that your children are your responsibility and must remain under your supervision at all times, including when they are participating in Children’s Programming events – Frederick Pagan Pride does not provide child care services. We expect everyone to: respect all participants, including those whose spiritual tradition, national origin, gender, sexual orientation, race, etc, differ from yours; be quiet in designated quiet areas/times and ritual spaces; be honest, trustworthy and considerate in dealing with others including Frederick Pagan Pride Staff, Vendors, and attendees (Pagan and non-Pagan alike).</p>
            <p><strong>Vendors</strong> While we do have vendors at Frederick Pagan Pride, please be aware that these vendors do not represent the organization in any way and FPP is not responsible for any information they may share or any products that they may sell during the event.</p>
            <p><strong>Photography &amp; Media</strong> Frederick Pagan Pride is a public, camera- friendly event. Your entry into this event constitutes your implied consent to be photographed and to have the resulting photographs published in print or online.</p>

            <h2>Frederick Pagan Pride Day is Organized by <br/>The Frederick Covenant of Unitarian Universalist Pagans <br/>(Frederick CUUPS)</h2>
            <p>Frederick CUUPS is an <strong>official member of the national Covenant of Unitarian Universalist Pagans (CUUPS)</strong>, an organization dedicated to networking Pagan-identified Unitarian Universalists, educating people about Paganism, promoting interfaith dialogue, developing Pagan liturgies and theologies, and supporting Pagan-identified UU religious professionals.</p>
            <h3>Frederick CUUPS Mission Statement</h3>
            <p>We, the Frederick CUUPS (Covenant of Unitarian Universalist Pagans) group of the Unitarian Universalist Congregation of Frederick, honor the UUA 7th principle; “Respect for the interdependent web of all existence of which we are a part”. We provide support to all seeking to live a life more in balance with nature by exploring the teachings and traditions of various pagan and Earth- centered religions and philosophies.</p>
            <p>We adhere to the mission statement of UUCF, National CUUPS, and the principles of the Unitarian Universalist Association. We promote personal responsibility and integrity, diversity and compassion, competency and education in the pursuit of spiritual balance.</p>
            <p>We offer our talents and abilities in service to our fellow congregants at UUCF and the greater Frederick community by providing celebrations to honor the Earth and its cycles. We seek both mirth and reverence, and we honor the Divine in many forms.</p>
            <h3>Frederick CUUPS Media</h3>
<a href="http://www.FrederickCUUPS.com">www.FrederickCUUPS.com</a> <a href="https://www.facebook.com/FrederickCUUPS">Facebook Page</a> <a href="https://frederickcuups.com/newsletter">Newsletter</a>
          </div>
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  conferenceDate: selectors.sessions.conferenceStart(state.sessions),
});

export default connect(
  mapStateToProps
)(About);
