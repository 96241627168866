import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState, selectors } from '../store';
import { IonPopover, IonIcon, IonSelect, IonSelectOption, IonHeader, IonToolbar, IonButtons, IonButton, IonMenuButton, IonContent, IonList, IonItem, IonLabel, IonDatetime, IonTitle } from '@ionic/react';
import './About.css';
import AboutPopover from '../components/AboutPopover';
import { calendar, pin } from 'ionicons/icons';

type Props = ReturnType<typeof mapStateToProps>

type State = {
  showPopover: boolean,
  showPopoverEvent: null | MouseEvent
}

class FAQ extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showPopover: false,
      showPopoverEvent: null
    };
  }

  presentPopover = (e: MouseEvent) => {
    this.setState(() => ({
      showPopover: true,
      showPopoverEvent: e
    }));
  }

  dismissPopover = () => {
    this.setState(() => ({
      'showPopover': false,
      'showPopoverEvent': null
    }));
  }

  render() {
    return (
      <>
        <IonHeader>
          <IonToolbar color="dark">
            <IonButtons slot="start">
              <IonMenuButton></IonMenuButton>
            </IonButtons>
            <IonTitle>FAQ</IonTitle>
            <IonButtons slot="end">
              <IonButton icon-only onClick={this.presentPopover}>
                <IonIcon slot="icon-only" name="more"></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonPopover
          isOpen={this.state.showPopover}
          event={this.state.showPopoverEvent}
          onDidDismiss={this.dismissPopover}
        >
          <AboutPopover
            dismissPopover={this.dismissPopover}
          />
        </IonPopover>

        <IonContent color="dark">
          <div className="ion-padding about-info">
            <h1>Federick Pagan Pride Day <br/> Frequently Asked Questions<br/>(FAQ)</h1>

            <h4>How do I get to Pagan Pride Day?</h4>
            <p>The address of the Unitarian Universalist Congregation of Frederick is:<br /><br/>
              <a href="https://www.google.com/maps/place/Unitarian+Universalist+Congregation+of+Frederick/@39.366792,-77.499078,17z/data=!3m1!4b1!4m2!3m1!1s0x89c9deeca8ef806f:0xe6f9518131fc91ef">4880 Elmer Derr Road<br />
                Frederick, MD 21703</a><br /><br/>
              Click the address above for a link to Google Maps</p>

            <h4>
              Who is invited to Pagan Pride Day?
</h4>
            <p>
              Pagan Pride Day is open to the general public, whether Pagan or non-Pagan. The purpose of our event is to encourage tolerance through education and information. The best way to do this is by our own example, demonstrating what are our beliefs truly are to those who come to learn about Paganism.
</p>

            <h4>
              How much does it cost to attend your event?
</h4>
            <p>
              Entry into Frederick Pagan Pride is free, but we ask for a donation to one of the two organizations we are supporting this year.  You are welcome to bring an item of non-perishable commercially prepared food (no home canned goods, unfortunately) for The Foodbank Program operated by the Frederick Community Action Agency.  This year, Frederick Pagan Pride Day is also supporting Frederick County Animal Control.  Donations for the Adoption Center can also be given as admission.  FCAC is requesting canned food of any variety, dry food (Iams is preferred, but they will take any sort), bleach, sponges and Dawn dish soap.  We can also accept cash donations for Frederick CUUPS (the group that organizes and staffs Frederick Pagan Pride Day).
</p>


            <h4>
              What happens in the event of inclement weather?
</h4>
            <p>
              Frederick Pagan Pride is rain or shine! In the event of inclement weather, we will move the entire festival indoors.  Sunny skies mean a mix of open and shaded locations, so sunscreen is always a good idea, possibly bug repellent as well.  Check the forecast beforehand and plan accordingly.
</p>


            <h4>
              Is the event accessible?
</h4>
            <p>
              Motorized wheelchairs and other forms of mobility assistance should not have many issues navigating the site. PPD will be held on the grounds of the The Unitarian Universalist Congregation of Frederick.  There is one hill leading down to a presentation area that is smooth but somewhat steep.  Volunteers will be available to assist with transport if necessary.
</p>


            <h4>
              What should I bring?
</h4>
            <p>
              We recommend bringing a camp or other folding chair for attending the workshops and watching performances.  There will be water filling stations, so a water bottle is also recommended.  Remember to bring some money for our wonderful vendors, cash if you would like to enter the raffle, a hand drum or other percussion if you’d like to participate in the drum circle, and sunblock/rain gear if you need it.
</p>


            <h4>
              Are the rituals open to everyone?
</h4>
            <p>
              Yes, our rituals are open to all those attending the event, including children as long as they can behave in a respectful and mature manner.
</p>


            <h4>
              What charities are you helping?
</h4>
            <p>
              We are collecting donations in place of admission for The Foodbank Program operated by the Frederick Community Action Agency and the Adoption Center at Frederick County Animal Control.  We can also accept cash donations for Frederick CUUPS (the group that organizes and staffs Frederick Pagan Pride Day).
</p>


            <h4>
              Respect &amp; Responsibility
</h4>
            <p>
              You are responsible for your welfare and the welfare of any children in your care, as well as any personal property in your care. We expect everyone to: respect all participants, including those whose spiritual tradition, national origin, gender, sexual orientation, race, etc, differ from yours; be quiet in designated quiet areas/times and ritual spaces; be honest, trustworthy and considerate in dealing with others including Frederick Pagan Pride Staff, Vendors, and attendees (Pagan and non-Pagan alike).
</p>


            <h4>
              Vendors
</h4>
            <p>
              While we will have vendors at Frederick Pagan Pride, please be aware that these vendors do not represent the organization in any way and FPP is not responsible for any information they may share or any products that they may sell during the event.  We in no way make guarantees about the quality of these items and services. However, if you do have questions or concerns about a particular vendor, please feel free to contact us and let us know.
</p>

<h4>
              Can I bring…?
</h4>
            <h5>
              …my dog or familiar to PPD?
</h5>
            <p>
              Service animals are welcome, of course, but please leave non-service animal companions at home.
</p>
            <h5>
              …my kids to PPD?
</h5>
            <p>
              Of course!  There is a playground on the church grounds that the kids can use during your visit with supervision.  We currently plan to offer some children’s programming as part of the event as well.  Please remember that your children are your responsibility and must remain under your supervision at all times – Frederick Pagan Pride does not provide child care services.
</p>
            <h5>
              …my athame/ritual sword/other weapon of ritual significance?
</h5>
            <p>
              No. Staffs and wands that are not overtly weapon-like are permitted, provided that common sense applies.
</p>

            <h4>
              Vending / Registration
</h4>
            <p>
              Do I have to be Pagan to vend? No. We welcome everyone at Frederick Pagan Pride! The goal of Pagan Pride Day is to allow non-pagans to learn about pagans as well as to build the local pagan sense of community.  See our Vendors &amp; Groups Page if you’re interested in vending.
</p>

            <h4>
              Is there electricity?
</h4>
            <p>
              There is electricity in some parts of the church.  If you have a special need for electricity access, please let us know so we can arrange it.
</p>

            <h4>
              Is there cellular reception?
</h4>
            <p>
              Yes.
</p>

            <h4>
              Is there wifi?
</h4>
            <p>
              The Unitarian Universalist Congregation of Frederick offers wifi within the church.  However, the further from the physical structure you go, the weaker the signal.  The festival takes place on the grounds outside the church, so we encourage people to plan accordingly.
</p>

            <h4>
              Photography &amp; Media
</h4>
            <p>
              Frederick Pagan Pride is a  public, camera-friendly event. Your entry into this event constitutes your implied consent to be photographed and to have the resulting photographs published in print or online. Please keep in mind that one of the objectives of the Pagan Pride Project is the public nature of Pride events, offering the general community access to information about who we are and what we do. Media will be invited and welcomed to this gathering and we have no control over what they choose to publish or otherwise use.  We appreciate your understanding and
  support of this policy.</p>
          </div>
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  conferenceDate: selectors.sessions.conferenceStart(state.sessions),
});

export default connect(
  mapStateToProps
)(FAQ);
